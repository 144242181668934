import * as React from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import Products from "../components/RentProducts";
import CommonButton from "../components/Button";
import { getIsVertical } from "../utils/getIsVertical";

import cenradis from "../files/Rūre_pakalpojumu_cenrādis.pdf";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../styles/mediciniskie-pakalpojumi-un-tehnisko-paliglidzeklu-noma.css";

export default function Rent() {
	const { t } = useTranslation();
	const isVertical = getIsVertical();

	return (
		<Layout pageId={PageId.Rent}>
			<Seo
				title={t("seoTitle")}
				description={t("seoDescription")}
			/>
			<div className="contentBlock topTextSection">
				<p className="rentTopSubText"><Trans i18nKey="topSubText" components={{ breakHorizontal: isVertical ? <></> : <br />, breakVertical: isVertical ? <br /> : <></> }} /></p>
				<h1><Trans i18nKey="topText" components={{ break: isVertical ? <></> : <br /> }} /></h1>
			</div>
			<div className="contentBlock">
				<div className="carePlan">
					<div>
						<h2>{t("rureOffer")}</h2>
						<p><Trans i18nKey="planPriceDescription" components={{ break: isVertical ? <></> : <br /> }} /></p>
					</div>
					<div>
						<p className="includedInCarePlan">{t("included")}</p>
						<div className="carePlanList">
							<div><Trans i18nKey="inPlan1" components={{ break: <br /> }} /></div>
							<div><Trans i18nKey="inPlan2" components={{ break: <br /> }} /></div>
							<div><Trans i18nKey="inPlan3" components={{ break: <br /> }} /></div>
							<div><Trans i18nKey="inPlan4" components={{ break: <br /> }} /></div>
							<div><Trans i18nKey="inPlan5" components={{ break: <br /> }} /></div>
							<div><Trans i18nKey="inPlan6" components={{ break: <br /> }} /></div>
							<div><Trans i18nKey="inPlan7" components={{ break: <br /> }} /></div>
							<div><Trans i18nKey="inPlan8" components={{ break: <br /> }} /></div>
						</div>
					</div>
				</div>
			</div>
			<div className="contentBlock pricePDF">
				<h1>{t("otherPriceList")}</h1>
				<a href={cenradis} download>
					<CommonButton>{t("downloadPDF")}</CommonButton>
				</a>
			</div>
			<div className="contentBlock noPadding">
				<div className="rentDescriptionBlock">
					<div className="rentDescriptionBlockTitle">
						<p>{t("functionalHelperRent")}</p>
					</div>
					<div>
						<p><Trans i18nKey="rentDescription1" components={{ break: isVertical ? <></> : <br /> }} /></p>
						<h1>{t("rentDescription2")}</h1>
					</div>
				</div>
			</div>
			<Products />
			<div className="contentBlock">
				<div className="contactsSection">
					<div className="left">
						<p>{t("contactUs")}</p>
						<div>
							<h2>{t("riga")}</h2>
							<h2>{t("phoneNr")}<a href="tel:+3718884">8884</a> <br /><a href="mailto:info.riga@rure.lv">info.riga@rure.lv</a></h2>
						</div>
					</div>
					<div className="right">
						<div>
							<p>{t("kurzemeDistrict")}</p>
							<h2><Trans i18nKey="cities1" components={{ break: <br /> }} /></h2>
							<h2>{t("phoneNr")}<a href="tel:+3718882">8882</a> <br /><a href="mailto:info.kurzeme@rure.lv">info.kurzeme@rure.lv</a></h2>
						</div>
						<div>
							<p>{t("latgaleDistrict")}</p>
							<h2><Trans i18nKey="cities2" components={{ break: <br /> }} /></h2>
							<h2>{t("phoneNr")}<a href="tel:+3718886">8886</a> <br /><a href="mailto:info.latgale@rure.lv">info.latgale@rure.lv</a></h2>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["mediciniskie-pakalpojumi-un-tehnisko-paliglidzeklu-noma", "RentProducts", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
